import 'jquery-validation/dist/jquery.validate.min';
import 'jquery-validation/dist/additional-methods.min';
import { module } from 'modujs';

export default class extends module {
  constructor(m) {
    super(m);
    this.events = {
      click: {
        submitAjax: 'submitAjax',
        submit: 'submit',
      },
    };

    this.ajaxurl = '/wp/wp-admin/admin-ajax.php';
  }

  init() {}

  submitAjax(e) {
    const target = e.currentTarget;
    var form = jQuery(target).parents('form');

    this.checkForm(form);

    if (form.valid()) {
      var data = new FormData(form[0]);
      $.ajax({
        url: this.ajaxurl,
        type: 'POST',
        data: data,
        processData: false,
        contentType: false,
        success: function (response) {
          if (response.redirectto) {
            window.location.href = response.redirectto;
          }
          form.find('.c-form_msg').addClass('u-margin-top-small');
          if (response.msg) {
            form.find('.c-form_msg').html(response.msg);
          }
          if (response.status == 'success') {
            form.find('.c-form_msg').removeClass('error');
            form.find('.c-form_msg').addClass('success');
            if (response.reset) {
              form[0].reset();
            }

            if (response.modal_content) {
              $('.c-modal').find('.c-modal_wrap').html(response.modal_content);
              $this.call('softOpen', false, 'Modal');
            }
            if (response.modal) {
              $this.call('close', false, 'Modal'); // update Locomotive Scroll
            }
          } else {
            form.find('.c-form_msg').removeClass('success');
            form.find('.c-form_msg').addClass('error');
          }
        },
        complete: function () {
          form.find('.c-form_action .c-button').show();
        },
      });
    } else {
      form.find('.c-form_msg').addClass('u-margin-top-small');
      form
        .find('.c-form_msg')
        .html('<p>Oops! Veuillez remplir les champs.</p>')
        .fadeIn(1000);
      form.find('.c-form_msg').removeClass('success');
      form.find('.c-form_msg').addClass('error');
    }
  }

  submit(e) {
    const target = e.currentTarget;
    var form = jQuery(target).parents('form');

    this.checkForm(form);

    if (form.valid()) {
      form.submit();
    } else {
      form.find('.c-form_msg').addClass('u-margin-top-small');
      form
        .find('.c-form_msg')
        .html('<p>Oups! Veuillez remplir les champs.</p>')
        .fadeIn(1000);
      form.find('.c-form_msg').removeClass('success');
      form.find('.c-form_msg').addClass('error');
    }
  }

  checkForm(form) {
    var $ = jQuery;
    var $msg_error = 'Ce champ est obligatoire';
    var $msg_error_email =
      'Saisir une adresse email valide (ex: email@exemple.com)';
    var $msg_error_nombre =
      'Saisir un nombre valide';
    var $msg_error_extension =
      'Types de fichiers autorisés .pdf, .jpg, .jpeg, .png, .docx, .doc, .odt';
    var $msg_error_filesize =
      'Fichier trop volumineux, la taille maximale autorisée est de 5Mo';
    var $msg_error_password =
      'Le mot de passe doit être identique';

    $.validator.addMethod(
      'filesize',
      function (value, element, param) {
        return this.optional(element) || element.files[0].size <= param;
      },
      $msg_error_filesize
    );

    $(form).validate({
      ignore: [],
      lang: 'fr',
      errorElement: 'div',
      errorPlacement: function (error, element) {
        var placement = $(element).data('error');
        if (placement) {
          $(placement).append(error);
        } else {
          error.insertAfter(element);
        }
      },
    });
    const required = this.$('required');
    const rules = [];
    required.forEach(function (element) {
      if (!$(element).parents('.is-hidden').length) {
        $(element).rules('add', {
          required: true,
          messages: { required: $msg_error },
        });
        if ($(element).attr('type') == 'email') {
          $(element).rules('add', {
            email: true,
            messages: { email: $msg_error_email },
          });
        } else if ($(element).attr('type') == 'file') {
          $(element).rules('add', {
            extension: 'jpeg|jpg|png|pdf|docx|doc|odt',
            filesize: 5242880,
            messages: { extension: $msg_error_extension },
          });
        } else if ($(element).attr('type') == 'number') {
          $(element).rules('add', {
            number: true,
            messages: { number: $msg_error_nombre },
          });
        } else if ($(element).attr('type') == 'password' && $(element).attr('id') == 'confirm_password') {
          $(element).rules('add', {
            equalTo: "#password",
            messages: { equalTo: $msg_error_password },
          });
        }
      }
    });

    const files = this.$('file');
    files.forEach(function (element) {
      if ($(element).attr('type') == 'file') {
        $(element).rules('add', {
          extension: 'jpeg|jpg|png|pdf|docx|doc|odt',
          filesize: 5242880,
          messages: { extension: $msg_error_extension },
        });
      }
    });
  }
}
