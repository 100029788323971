import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
        this.events = {
            click: {
                nav: 'toggleNav',
                subnav: 'toggleSubNav',
            }
        }
    }

    init() {

    }

    toggleNav(e) {
        const burger = this.$('nav')[0];
        const html = document.querySelector('html');
        if(html.classList.contains('has-navOpen')) {
            html.classList.remove('has-navOpen');
            burger.classList.remove('is-active');
            $('#nav .c-nav_container').css('height', '0px');
        } else {
            html.classList.add('has-navOpen');
            burger.classList.add('is-active');
            let nav_height = $('#nav .c-nav_wrap').outerHeight();
            $('#nav .c-nav_container').css('height', nav_height+'px');
        }
    }

    toggleSubNav(e) {
        const el = e.currentTarget;

        $(el).parent('.c-nav_primary-item').toggleClass('is-open');
    }
}
