import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
        this.events = {
            click: {
                open: 'open',
            }
        }
    }

    init() {
        
    }
    
    open(e) {
        const index = e.currentTarget.getAttribute('data-index');
        
        if (e.currentTarget.classList.contains('is-active')) {
            /**
             * Déjà ouvert, on referme
             */
            document
                .querySelectorAll('[data-tabIndex="' + index + '"]')
                .forEach((element) => element.classList.remove('is-active'));
            e.currentTarget.classList.remove('is-active');
            if (e.currentTarget.getAttribute('data-textClosed')) {
                e.currentTarget.innerHTML = e.currentTarget.getAttribute(
                'data-textClosed'
                );
            }
        } else {
            /**
             * Fermé, on affiche la section cachée
             */
            e.currentTarget.classList.add('is-active');
            const items = document.querySelectorAll(
                '[data-tabIndex="' + index + '"]'
            );
            items.forEach((element) => element.classList.add('is-active'));
            if (e.currentTarget.getAttribute('data-textOpen')) {
                e.currentTarget.innerHTML = e.currentTarget.getAttribute(
                'data-textOpen'
                );
            }
        }        
    }
}
