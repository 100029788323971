import { module } from 'modujs';
import LocomotiveScroll from 'locomotive-scroll';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        this.scroll = new LocomotiveScroll({
            el: this.el,
            smooth: true,
            reloadOnContextChange: true,
            touchMultiplier: 4,
            tablet: {
              smooth: false,
            },
            smartphone: {
              smooth: false,
            }
        });

        if($(document).outerWidth() < 501) {
          $('.b-relation-pages_wrap .c-card').attr('data-scroll-speed', 0);
        }

        setTimeout(() => {
          this.scroll.update();
        }, 500);
        new ResizeObserver(() => this.scroll.update()).observe(this.el);

        $('[data-scroll-call="animateNumber"]').each(function(){
          if($(this).hasClass('is-inview')) {
            $(this).prop('Counter',0).animate({
              Counter: $(this).text(),
            }, {
              duration: 4000,
              easing: 'swing',
              step: function (now) {
                $(this).text(Math.ceil(now).toLocaleString('cs-CZ'));
              },
            });
          }
        });

        /* Lazyload Image */
        var lazyImages = [].slice.call(document.querySelectorAll('img'));
        if ('IntersectionObserver' in window) {
          var lazyImageObserver = new IntersectionObserver(function(entries) {
            entries.forEach(function(entry) {
              if(entry.isIntersecting) {
                var lazyImage = entry.target;
                if(lazyImage.getAttribute('data-load-src')) {
                  lazyImage.src = lazyImage.getAttribute('data-load-src');
                }
                // if (lazyImage.dataset.srcset) {
                //   lazyImage.srcset = lazyImage.dataset.srcset;
                // }
                lazyImageObserver.unobserve(lazyImage);
              }
            });
          });

          lazyImages.forEach(function(lazyImage) {
            lazyImageObserver.observe(lazyImage);
          });
        } else {
          $('img').each(function(){
            $(this).attr('src', $(this).attr('data-load-src'));
          });
        }

        var BGlazyImages = [].slice.call(document.querySelectorAll('.o-ratio_content_bg'));
        if ('IntersectionObserver' in window) {
          var BGlazyImageObserver = new IntersectionObserver(function(entries) {
            entries.forEach(function(entry) {
              if(entry.isIntersecting) {
                var lazyImage = entry.target;
                if(lazyImage.getAttribute('data-load-style')) {
                  lazyImage.style = lazyImage.getAttribute('data-load-style');
                }
                BGlazyImageObserver.unobserve(lazyImage);
              }
            });
          });

          BGlazyImages.forEach(function(lazyImage) {
            BGlazyImageObserver.observe(lazyImage);
          });
        } else {
          $('.bg-lazy').each(function(){
            $(this).css('background-image', 'url('+$(this).attr('data-src')+')');
          });
        }
        /* Lazyload Image */

        this.scroll.on('call', (func, way, obj, id) => {
            // Using modularJS
            this.call(func[0], { way, obj }, func[1], func[2]);

            if(func == 'animateNumber') {
              $(obj).prop('Counter',0).animate({
                Counter: $(obj.el).text(),
              }, {
                duration: 4000,
                easing: 'swing',
                step: function (now) {
                  $(obj.el).text(Math.ceil(now).toLocaleString('cs-CZ'));
                },
              });
            }
        });

      this.scroll.on('scroll', (args) => {
        if(args.scroll.y > 50) {
          $('.c-header').addClass('is-sticky');
        }else {
          $('.c-header').removeClass('is-sticky');
        }
      })

      // Disable Locomotive scroll when using arrows on input (ex: autocomplete)
      let locoScroll = this.scroll;
      $(':input')
        .keydown(function (e) {
          if (e.which == 38 || e.which == 40) {
            locoScroll.stop();
          }
        })
        .keyup(function (e) {
          locoScroll.start();
        });
    }

    toggleLazy(args) {
        let src = this.getData('lazy', args.obj.el)
        if (src.length) {
            if (args.obj.el.tagName === 'IMG') {
                args.obj.el.src = src
            } else {
                args.obj.el.style.backgroundImage = `url('${src}')`
            }
            this.setData('lazy', '', args.obj.el)
        }
    }

    destroy() {
        this.scroll.destroy();
    }

    scrollTo(target) {
      this.scroll.scrollTo(target, {'offset': -150});
    }

    update() {
      this.scroll.update();
    }

}
